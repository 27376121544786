import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import featureImage from "../../static/images/living-hero.png";
import thumbnailSize from "../../static/images/feature-size.png"
import thumbnailColors from "../../static/images/feature-colors.png"
import thumbnailPhone from "../../static/images/feature-phone.png"
// import thumbnailTeams from "../../static/images/feature-team.png"
// import thumbnailStaff from "../../static/images/feature-user.png"

const IndexPage = () => (
  <Layout>
    <SEO title="Parologio"/>

    <div className={"banner"}>
      <div className={"content"}>
        {/* <h2>Parologio</h2> */}
        {/* <img alt={"Foto di un soggiorno con il parologio"} src={featureImage}/> */}
        <h2>Un nuovo modo di leggere l'ora.</h2>
      </div>
    </div>

    <div className={"container"}>
      <div className={"features"}>
        <div className={"feature__item"}>
          <div className={"row"}>
            <div className={"col-6 first"}>
              <div className={"thumbnail"}>
                <img alt={"Size"} src={thumbnailSize}/>
              </div>
            </div>

            <div className={"col-6"}>
              <div className={"feature__content"}>
                <h2>Diversi formati</h2>
                <p>Scegli la dimensione e forma che preferisci!</p>
              </div>
            </div>
          </div>
        </div>

        <div className={"feature__item"}>
          <div className={"row"}>
            <div className={"col-6"}>
              <div className={"feature__content"}>
                <h2>Personalizza i colori</h2>
                <p>Cornice bianca o nera? Colore di fondo rosso o blu, personalizza in base al tuo stile.</p>
              </div>
            </div>

            <div className={"col-6 first"}>
              <div className={"thumbnail"}>
                <img alt={"Colors"} src={thumbnailColors}/>
              </div>
            </div>
          </div>
        </div>

        <div className={"feature__item"}>
          <div className={"row"}>
            <div className={"col-6 first"}>
              <div className={"thumbnail"}>
                <img alt={"News"} src={thumbnailPhone}/>
              </div>
            </div>

            <div className={"col-6"}>
              <div className={"feature__content"}>
                <h2>Smart</h2>
                <p>Controlla i colori e le impostazioni con il tuo smartphone.</p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className={"feature__item"}>
          <div className={"row"}>
            <div className={"col-6"}>
              <div className={"feature__content"}>
                <h2>Teams</h2>
                <p>HiStaff let you add staff in specific teams and manage them easily.</p>
              </div>
            </div>

            <div className={"col-6 first"}>
              <div className={"thumbnail"}>
                <img alt={"Team"} src={thumbnailTeams}/>
              </div>
            </div>
          </div>
        </div>

        <div className={"feature__item"}>
          <div className={"row"}>
            <div className={"col-6 first"}>
              <div className={"thumbnail"}>
                <img alt={"Users"} src={thumbnailStaff}/>
              </div>
            </div>

            <div className={"col-6"}>
              <div className={"feature__content"}>
                <h2>Staff management</h2>
                <p>Discard traditional ways to archive staff documents and information, and try new ways to store and archive them in HiStaff easily.</p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>

    {/* <div className={"call-to-action"}>
      <div className={"container"}>
        <div className={"call-to-action__content"}>
          <h2>Sign up for free</h2>
          <p>Sign up and start increasing the productivity of your business with HiStaff.</p>
        </div>

        <div className={"button"}>
          <a href="https://app.histaff.io" target={"_blank"}>Get Started</a>
        </div>
      </div>
    </div> */}
  </Layout>
)

export default IndexPage
